import logo from './logo.svg';
import './App.css';
import { useEffect, useState, createElement } from 'react';
import axios from 'axios'

const API_ENDPOINT = 'https://qcuhto7rs3.execute-api.ap-northeast-1.amazonaws.com/default/wearware'

const PRODUCTS = [
  {
    id: 'BLACK_SHIRT',
    title: 'black sweatshirt',
    img: require('./girl.png'),
    price: '29.99',
    description: ''
  },  
  {
    id: 'WHITE_SHIRT',
    title: 'white sweatshirt',
    img: require('./sweatshirt.png'),
    price: '28.99',
    description: ''
  },
  {
    id: '1',
    title: 'wristband - v.1',
    img: require('./sweatshirt2.png'),
    price: '7.99',
    description: ''
  },
  {
    id: '3',
    title: 'beanie - v.1',
    img: require('./girl2.png'),
    price: '19.99',
    description: ''
  },
]

const Roadmap = () => {
  return (
    <div className="roadmap" style={{ marginTop: 0 }}>
      <div className="big-title" style={{ maxWidth: 270, margin: 'auto' }}>
        <h1>What's next</h1>
        <p style={{ paddingTop: 3 }}>Read about upcoming releases and why join already now.</p>
      </div>
      <div style={{ textAlign: 'center', paddingBottom: 30 }}>
        <div className="">
          <h2 className="roadmap-year">2023</h2>
        </div>
        <div className="roadmap-node">
          <div className="roadmap-node-title">
            <p className="medium-text">● </p>
              <div style={{ position: 'relative', right: -7, textAlign: 'left' }}>
                <p className="medium-text">December</p>
                <p className="small-text">Release of version 2. At this point AI-designs are implemented.</p>
              </div>
          </div>
          <div className="roadmap-node-description">
            
          </div>
        </div>
        <div className="">                
          <h2 className="roadmap-year">2024</h2>                
        </div>
        <div className="roadmap-node">
          <div className="roadmap-node-title">
              <p className="medium-text">● </p>
              <div style={{ position: 'relative', left: -140, textAlign: 'right' }}>
              <p className="medium-text">January</p>
                <p className="small-text">Release of version 2. At this point AI-designs are implemented.</p>
              </div>
          </div>
          <div className="roadmap-node-description">
            
          </div>
        </div>
        <div className="roadmap-node">
          <div className="roadmap-node-title">                  
            <p className="medium-text">● </p>
              <div style={{ position: 'relative', right: -7, textAlign: 'left' }}>
                <p className="medium-text">February</p>
                <p className="small-text">Release of version 2. At this point AI-designs are implemented.</p>
              </div>
          </div>
          <div className="roadmap-node-description">
            
          </div>
        </div>
        <div className="roadmap-node">
          <div className="roadmap-node-title">
            <p className="medium-text">● </p>
              <div style={{ position: 'relative', left: -140, textAlign: 'right' }}>
                <p className="medium-text">March</p>
                <p className="small-text">Release of version 2. At this point AI-designs are implemented.</p>
              </div>
          </div>
          <div className="roadmap-node-description">                  
          </div>
        </div>
        <h2 className="roadmap-year">.........</h2>    
      </div>
      <div style={{  marginTop: -1, paddingBottom: 15 }}>
        <div style={{ paddingBottom: 0, paddingTop: 20 }}>          
          <p className="button-description" style={{ paddingBottom: 0 }}>Email us for any questions</p>
          <h2 style={{ userSelect: 'all' }}>weav.tokyo@gmail.com</h2>
        </div>        
      </div> 
    </div>     
  )
}

const LandingPage = ({ path, item, setHighlightedItem, scrollPosition }) => {
  return (
    <div className="" style={{ }}>
      <div className="section"><div className="section"><h1>weav.tokyo</h1></div></div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <div className="clear-button" style={{ flex: 0.8, maxWidth: 110, display: 'flex', flexDirection: 'column' }}>      
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, padding: 7 }}>
              <img src={require('./qr-code.png')} className="clear-button-img" />  
              <p className="promo-text">{ 'Order now' }</p>
              <p className="promo-description">{ 'After purchase the product will be sent.' }</p>  
            </div>   
            <div>
              <img src={require('./remove.png')} className="rotate90 pale" style={{ height: 75, width: 45, objectFit: 'contain' }} />
            </div> 
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
              <div className="center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 7 }}>
                <img src={require('./email.png')} className="clear-button-img" />
                <p className="promo-text">{ 'Receive email' }</p>
                <p className="promo-description">{ 'An email with your customization link will be sent.' }</p>  
              </div>
            </div>  
            <div>
              <img src={require('./remove.png')} className="rotate90 pale" style={{ height: 75, width: 45, objectFit: 'contain' }} />
            </div>  
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
              <div className="center" style={{ display: 'flex', flexDirection: 'column', padding: 7 }}>
                <img src={require('./directions.png')} className="clear-button-img" />
                <p className="promo-text">{ 'Add your link' }</p>
                <p className="promo-description">{ 'Use the emailed link to edit where the QR-code redirects to.' }</p>  
              </div>
            </div>  
            <div>
              <img src={require('./remove.png')} className="rotate90 pale" style={{ height: 75, width: 45, objectFit: 'contain' }} />
            </div> 
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
              <div className="center" style={{ display: 'flex', flexDirection: 'column', padding: 7 }}>
                <img src={require('./growth.png')} className="clear-button-img" />
                <p className="promo-text">{ 'Get commission' }</p>
                <p className="promo-description">{ 'If someone purchases a product using your QR-code, you will receive 10% of the sale.' }</p>  
              </div>
            </div>   
          </div>
          <div style={{ flex: 1 }}>
            <div className="collection">   
              { /*PRODUCTS.map(product => (
                <div onClick={e => { setHighlightedItem(product) }} className="hover button">
                  <img src={product.img} className="button-img" />
                  <p className="button-title">{ product.title }</p>
                  <p className="button-description">{ product.price }</p>
                </div>
              )) */}     
              <div onClick={e => { setHighlightedItem(PRODUCTS[0]) }} className="hover button" style={{ position: 'relative', top: (scrollPosition / -20) }}>
                <img src={PRODUCTS[0].img} className="button-img" />
                <p className="button-title">{ PRODUCTS[0].title }</p>
                <p className="button-description">{ PRODUCTS[0].price }</p>
              </div> 
              <div onClick={e => { setHighlightedItem(PRODUCTS[1]) }} className="hover button" style={{ position: 'relative', top: (scrollPosition / -20) }}>
                <img src={PRODUCTS[1].img} className="button-img" />
                <p className="button-title">{ PRODUCTS[1].title }</p>
                <p className="button-description">{ PRODUCTS[1].price }</p>
              </div>             
            </div>
            <div className="collection">   
              { /*PRODUCTS.map(product => (
                <div onClick={e => { setHighlightedItem(product) }} className="hover button">
                  <img src={product.img} className="button-img" />
                  <p className="button-title">{ product.title }</p>
                  <p className="button-description">{ product.price }</p>
                </div>
              )) */}     
              <div onClick={e => { setHighlightedItem(PRODUCTS[2]) }} className="hover button" style={{ position: 'relative', top: (scrollPosition / -20) }}>
                <img src={PRODUCTS[2].img} className="button-img" />
                <p className="button-title">{ PRODUCTS[2].title }</p>
                <p className="button-description">{ PRODUCTS[2].price }</p>
              </div> 
              <div onClick={e => { setHighlightedItem(PRODUCTS[3]) }} className="hover button" style={{ position: 'relative', top: (scrollPosition / -20) }}>
                <img src={PRODUCTS[3].img} className="button-img" />
                <p className="button-title">{ PRODUCTS[3].title }</p>
                <p className="button-description">{ PRODUCTS[3].price }</p>
              </div>             
            </div>   
            <div className="roadmap-button hide-on-mobile hide-on-extra-wide" style={{ marginTop: 0 }}>
              <Roadmap />  
            </div>   
          </div>   
          <div className="roadmap-button show-on-extra-wide" style={{ marginTop: 0 }}>
            <Roadmap />  
          </div>             
        </div>    
        <div className="roadmap-button show-on-mobile" style={{  marginTop: 2 }}>
          <Roadmap />  
        </div>     
    </div>
  )
}


const ReferralPage = ({ path, item, scrollPosition, setHighlightedItem }) => {
  const hasDestination = item && item.destination && item.destination !== 'URL_HERE'
  const [ urlInput, setUrlInput ] = useState(hasDestination || '')

  useEffect(() => {
    if (hasDestination) {
      setUrlInput(item.destination)
    }
  }, [ item ])

  const product = item && PRODUCTS.find(_ => _.id === item.itemId)

  return (
    <div className="">
      <div className="section"><div className="section" onClick={e => { window.location.href = '/' }} style={{ cursor: 'pointer' }}><h1>weav.tokyo</h1></div></div>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
        { !!product && (
        <div onClick={e => { setHighlightedItem(product) }} className="hover button" style={{ maxWidth: 250 }}>
          <img src={product.img} className="button-img" />
          <p className="button-title">{ product.title }</p>
          <p className="button-description">{ product.price }</p>
        </div>) }
      </div>
      <div style={{ marginTop: 60 }} />
      <div className="explanation" style={{ marginTop: (scrollPosition / -20) }}>
        <div className="big-title" style={{ paddingBottom: 5 }}>
          <h1>Referral</h1>
        </div>
        <div>
        <div className="section"><p>The person who referred you will be credited</p></div>      
        </div>
        <div className="collection" style={{ padding: 50, paddingTop: 30 }}>
        { PRODUCTS.map(product => (
          <div onClick={e => { setHighlightedItem(product) }} className="hover button">
            <img src={product.img} className="button-img" />
            <p className="button-title">{ product.title }</p>
            <p className="button-description">{ product.price }</p>
          </div>
        )) }   
      </div>
      </div>    
      <div className="big-title" style={{ paddingBottom: 15, paddingTop: 15, backgroundColor: 'rgba(255,255,255,1)', textAlign: 'center' }}>
        <p>copyright by weav.tokyo</p>
      </div>   
    </div>
  )
}

const ReferralManagementPage = ({ path, item, scrollPosition, search }) => {
  const hasDestination = item && item.destination && item.destination !== 'URL_HERE'
  const [ urlInput, setUrlInput ] = useState(hasDestination || '')

  useEffect(() => {
    if (hasDestination) {
      setUrlInput(item.destination)
    }
  }, [ item ])

  const product = item && PRODUCTS.find(_ => _.id === item.itemId)

  return (
    <div className="">
      <div className="section"><div className="section" onClick={e => { window.location.href = '/' }} style={{ cursor: 'pointer' }}><h1>weav.tokyo</h1></div></div>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
        { !!product && (
        <div className="button" style={{ maxWidth: 250 }}>
          <img src={product.img} className="button-img" />
          <p className="button-title">{ product.title }</p>
        </div>) }
      </div>
      <div style={{ marginTop: 60 }} />
      <div className="explanation" style={{ marginTop: (scrollPosition / -20) }}>
        <div className="big-title" style={{ paddingBottom: 5 }}>
          <h1>Customization</h1>
        </div>
        <div>
        <div className="section"><p>Edit where the QR code redirects to</p></div>      
          <div className="section">
            <input className="big-input" placeholder="https://example.com..." value={urlInput} onChange={e => setUrlInput(e.target.value)}></input>
          </div>      
          <div className="section hover button" style={{ maxWidth: 200, margin: 'auto', padding: 5, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} onClick={e => {
            axios({
              method: 'PUT',
              url: API_ENDPOINT,
              data: {
                itemId: item.id,
                itemSecret: search,
                itemDestination: urlInput
              }
            }).then(_ => {
              console.log('Result: ', _)
              const data = _.data
              console.log('item: ', data)              
            })
          }}>
            <div style={{  }}> 
              <img src={require('./save.png')} className="button-img" style={{ height: 15, maxWidth: 15, objectFit: 'contain', paddingRight: 5 }} />
            </div>
            <p>Save { search }</p>
          </div>
          <div style={{ height: 30 }} />
          <div className="big-title" style={{ paddingBottom: 15, paddingTop: 15, backgroundColor: 'rgba(255,255,255,1)', }}>
            <p>copyright by weav.tokyo</p>
          </div>
        </div>
      </div>     
    </div>
  )
}

const DEFAULT_PAGE = 'DEFAULT_PAGE'
const REFERRAL_PAGE = 'REFERRAL_PAGE'
const REFERRAL_MANAGEMENT_PAGE = 'REFERRAL_MANAGEMENT_PAGE'

const PAGES = [
  {
    id: DEFAULT_PAGE,
    description: 'Landing page',
    path: '/', // Regex todo,
    component: LandingPage
  },
  {
    id: REFERRAL_PAGE,
    description: 'Page where user ends up after scanning a QR - code.',
    path: '/', // Regex todo
    component: ReferralPage // LandingPage
  },
  {
    id: REFERRAL_MANAGEMENT_PAGE,
    description: 'Page to edit where the QR code points to.',
    path: '/', // Regex todo
    component: ReferralManagementPage
  },
]

const matchPage = (path, pages, search) => {
  if (path === '' || path === '/') {
    return PAGES.find(_ => _.id === DEFAULT_PAGE)
  }  
  if (path !== '' && search) {
    return PAGES.find(_ => _.id === REFERRAL_MANAGEMENT_PAGE)
  }
  if (path !== '') {
    return PAGES.find(_ => _.id === REFERRAL_PAGE)
  }
  return PAGES.find(_ => _.id === DEFAULT_PAGE)
} 

function App() {
  const [ path, setPath ] = useState('')
  const [ search, setSearch ] = useState(null)
  const [ item, setItem ] = useState(null)
  const [ highlightedItem, setHighlightedItem ] = useState(null)
  const [ showInstructions, setShowInstructions ] = useState(false)

  const isLandingPage = path === '' || path === '/'
  const isReferralPage = path && path !== '/' && !(search)
  const isReferralManagementPage = path && path !== '/' && search

  const [ scrollPosition, setScrollPosition ] = useState(0)

  useEffect(() => {
    if (window.innerWidth > 600) {
    window.addEventListener('scroll', throttle(parallax, 14));

      function throttle(fn, wait) {
        var time = Date.now();
        return function() {
          if ((time + wait - Date.now()) < 0) {
            fn();
            time = Date.now();
          }
        }
      };

      function parallax() {        
        let scroll = window.pageYOffset;
        const TRESHOLD = 5
        if (((scroll+TRESHOLD) > scrollPosition) || ((scroll-TRESHOLD) < scrollPosition) ) {
          setScrollPosition(Math.floor(scroll))
        }
      };
    }
  }, [])

  useEffect(() => {
    let currentPath = window.location.pathname// + window.location.search
    let currentSearch = window.location.search
    if (currentSearch) {
      currentSearch = new URLSearchParams(currentSearch)
      currentSearch = currentSearch.get('s')
    }
    currentPath = currentPath.substring(1)
    setPath(currentPath)
    setSearch(currentSearch)
  }, [])

  useEffect(() => {
    if (!isLandingPage) {
      axios({
        method: 'POST',
        url: API_ENDPOINT,
        data: {
          id: path
        }
      }).then(_ => {
        console.log('Result: ', _)
        const data = _.data
        console.log('item: ', data)
        setItem(data)
      })
    }
  }, [ path ])

  useEffect(() => {
    const hasDestination = item && item.destination && item.destination !== 'URL_HERE'
    if (isReferralPage && hasDestination) {
      const url = (item.destination.includes('https://') || item.destination.includes('http://')) ? item.destination : ('https://' + item.destination)
      window.location.href = url
    }
  }, [ item ])

  const page = matchPage(path, PAGES, search)
  const pageComponent = createElement(page.component, { path, item, scrollPosition, setShowInstructions, setHighlightedItem, search })

  return (
    <div className="App">
      { pageComponent }
      <div onClick={e => { window.scrollTo({ left: 0, top: 960, behavior: 'smooth' }) }} className="hover hamburger-menu">
        <img src={require('./question.png')} className="hamburger-img" />
      </div>
      { showInstructions && (
      <div onClick={e => { setShowInstructions(false) }} className="instruction-modal">
        <h1>Instructions</h1>
      </div> )}
      { !!highlightedItem && (
      <div className="highlight-modal">
        <div className="product-container">
        <div className="product" onClick={e => { setHighlightedItem(null) }}>
          <img src={highlightedItem.img} className="button-img" style={{ objectFit: 'contain', maxHeight: '40vh' }} />
          <p className="button-title">{ highlightedItem.title }</p>
          <p className="button-description">{ highlightedItem.price }</p>
        </div>
        <div className="product" style={{ borderLeft: '1px solid rgba(0,0,0,0.05)' }}>         
          <p className="button-description" style={{ padding: 30, paddingTop: 5, paddingBottom: 5 }}>Customizable design and custom editable QR code link to your online presence.</p>
          
          <div style={{ flex: 1, minHeight: 10 }} />

          
          <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="RTXLF5QRS83RQ" />
              <table style={{  margin: 'auto' }}>
                  <td style={{ paddingRight: 10, paddingBottom: 30 }}>
                    <input type="hidden" name="on0" value="Size"/>
                    <p>Size</p>
                  </td>             
                  <td>
                    <select name="os0">
                      <option value="S">
                        S
                      </option>
                      <option value="M">
                        M
                      </option>
                      <option value="L">
                        L
                      </option>
                      <option value="XL">
                        XL
                      </option>
                    </select>
                  </td>
              </table>
              <input type="hidden" name="currency_code" value="USD" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
            </form>
          </div>
          <div style={{ height: 130 }} />
        </div>
        </div>
      </div> )}
    </div>
  );
}

export default App;
